<template>
  <div id="login_page">
    
        <div id="form_wrapper" md-theme="default-dark" v-if="!password_reset">
            <div class="top">
                <div class="logo_grill"></div>
          <h3>כניסה לחשבון</h3> 
          </div>
          <div class="content">
          
           <div v-if="is_loading">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-if="!is_loading">
            <div v-if="!show_step_b">
                <form @submit.prevent="login()">
                    <md-field>
                        <label>שם משתמש</label>
                        <md-input v-model="username" required=""></md-input>
                    </md-field>

                    <md-field>
                        <label>סיסמא</label>
                        <md-input type="password" v-model="password" required=""></md-input>
                    </md-field>
                <div style="margin-bottom:15px;"><input type="submit" value="התחבר" class="btn"></div>
                



                </form>
            </div>
            
            </div>
            </div>

        </div>
        </div>
 

</template>


<script>


export default {
  data(){
      return {

      FB: {},
      model: {},
      scope: {},
        password_reset:false,
        token: '',
        password_send:false,
        show_step_b: false,
        is_loading: false,
        username: '',
        password: '',
        remember_me: false,
        show_error: false
      }
  },
  created(){
      
    
  },
  methods:{
      handleSdkInit({ FB, scope }) {
        this.FB = FB
        this.scope = scope
console.log(FB)
console.log(scope)
console.log(this.model)

      },
    login()
    {
      var self = this;
      self.is_loading = true;


    const formData = { 
    password: this.password,
    username: this.username 
    };

    this.net_api({ 'action': 'chr_login', data: formData}, function(data){
       
        if (!data.data > 0)
        {
            self.is_loading = false;
            alert("שם משתמש או סיסמה אינם נכונים");
            //alert('Wrong details. Please try again.');
        }
        else
        {
            localStorage.setItem('is_logged', true);
            localStorage.setItem('platform', data.data);
            self.$store.platform_id = data.data;
            document.location = document.location.href;

            //alert(data.jwt)
            //captainnemo
        }
        
    });

    },
 


  }
}
</script>

<style scoped>
.content{margin-bottom: 0 !important;width: 100%;}
.top{background-image:url(../assets/fire.jpg);background-size: cover;width: 100%;background-position: center;}
.close{    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 20px;
    font-size: 24px;
    font-weight: 400;
    color: #fff !important;
    font-family: cursive;}
 .logo_grill{height: 100px;width: 100%;background-image:url(../assets/logo_grill.png);background-size: contain;background-repeat: no-repeat;background-position: center;}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}
.md-field input { color: #333 !important; -webkit-text-fill-color: #333;}

/*.fb_btn {
    text-align: center;
    width: 100%;
    height: 48px;
    line-height: 48px;

    display: inline-block;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
    padding-left: 41px;
    margin-bottom: 15px;
}*/

</style>