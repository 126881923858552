<template>
<div>
<div class="col-md-3">
    <div class="prouduct">
        <div class="pic" :style="'background-image:url('+ prod.pic +')'"  @click="showDialog = true"></div>
        <h3 v-text="prod.name" class="prod_name"></h3>
        <span class="price reale" >{{ format_number(prod.price) }} ש"ח</span>
        <span class="discount price" >{{format_number(prod.price_discount)}} ש"ח</span>
        <div class="btn" @click="showDialog = true">לפרטים</div>
        <div class="btn" @click="$emit('addToBasket', prod.id);scrollToBottom()" >לרכישה</div>
    </div>
     <md-dialog :md-active.sync="showDialog" >
<div class="close_pop"  @click="showDialog = false">X</div>
      <md-tabs md-dynamic-height>
        <md-tab md-label="פרטי המוצר">
         
            <div class="col-md-6" style="padding-top:0px;text-align:center">
              <h3 v-text="prod.name" class="prod_name_popup"></h3>
            <p v-html="prod.short_text" class="descrip"></p>
          <span class="discount price" >{{format_number(prod.price_discount)}} ש"ח</span>
                    <span class="price reale" >{{ format_number(prod.price) }} ש"ח</span>


            </div>
             <div class="col-md-6">
            <div class="pic" :style="'background-image:url('+ prod.pic +')'"></div>
            </div>
                    <div class="btn_buy_spec inner_btn" @click="$emit('addToBasket', prod.id);scrollToBottom();showDialog = false" >לרכישה</div>

        </md-tab>
           <md-tab md-label="מפרט טכני">
             <div class="spec">
            <p v-html="prod.description" class="descrip" ></p>
            
            </div>
                   <div class="btn_buy_spec inner_btn" @click="showDialog = false;$emit('addToBasket', prod.id);scrollToBottom();showDialog = false"  > לרכישה</div>

        </md-tab>
        <md-tab md-label="סרטון" v-if="prod.youtube">
          <iframe id="ytplayer" type="text/html" width="100%" height="400px"
            :src="'https://www.youtube.com/embed/'+ prod.youtube +'?autoplay=1&origin=http://example.com'"
            frameborder="0"></iframe>          <div class="btn_buy_spec inner_btn" @click="$emit('addToBasket', prod.id);scrollToBottom();showDialog = false" >לרכישה</div>


             </md-tab>


       
      </md-tabs>

   
    </md-dialog>
    </div>
    </div>
    
</template>
<script>

export default {
    props:["prod"],
     data(){
    return {
      showDialog: false,
    }
    
  },
  methods:{
    scrollToBottom() {
   window.scroll({
  top: 2000, 
  left: 0, 
  behavior: 'smooth' 
});
  },



  },
    
}
</script>
<style lang="scss">
.md-dialog-container .price{margin-top: 65px;}
.md-dialog-container{max-height: 500px !important;position: relative;border: 4px solid #010c46;max-width: 50% !important;}
.close_pop{position: absolute;left: 10px;top: 10px;z-index: 999;color: #010c46 !important;font-weight: bolder;cursor: pointer;}
.md-dialog-container iframe{margin: 0 auto;width:  50%;margin-right: 25%;}
.spec{height: 300px; overflow: auto;padding-right: 20px;direction: rtl;}
.md-dialog-container .md-tabs{overflow: auto}
.md-dialog-container .md-content{direction: ltr;}
.md-dialog-actions{position: absolute;left: 10px;bottom: 20px;}
.descrip{font-size: 15px;}
.reale{text-decoration: line-through;color: #868585!important;}
.prouduct{background: #eee; text-align: center;padding-top: 10px;margin-bottom: 70px;}
.pic{width: 70%;height: 300px;margin: 30px auto;background-size: contain;background-position: center;background-repeat: no-repeat;}
.md-dialog-container .pic{width: 350px;height: 350px;background-size: cover;background-position: center;background-repeat: no-repeat;}
.btn{width: 50%;display: inline-block;vertical-align: middle; height: 45px;cursor: pointer;margin-top: 20px;}
.btn:hover{transform: scale(1.01);}
.btn:nth-child(odd){ color: #010c46;line-height: 35px;border: 3px solid;background: #fff;}
.btn:nth-child(even){ background-color: #010c46;line-height: 42px;color: #fff;}
.btn_buy{width: 50%;display: inline-block;vertical-align: middle; height: 45px;cursor: pointer;margin-top: 30px;background-color: #010c46;color: #fff;line-height: 40px;}
.btn_buy_spec{width: 20%;display:block; height: 40px;cursor: pointer;background-color: #010c46;color: #fff;line-height: 40px;text-align: center}
.price{width: 50%;display: inline-block;margin: 10px 0px;font-weight: 600;color: #ea0000;direction: rtl;}
h3{margin: 20px auto !important;    min-height: 60px; max-height: 60px; line-height: 30px;  overflow: hidden;}
.prod_name_popup{margin: 0px auto !important; }
.md-tab{position:relative ;padding: 0 !important;}
.inner_btn{    position: absolute;
    bottom: 7px;
    left: 7px;}
</style> 