<template>
    <div class="basket" id="basket"  >
     <h3 class="basket_title">סל קניות</h3>
     <div class="items">
         <h4 v-if="getTotal == 0">סל הקניות ריק</h4>
         <div class="item" v-for="(item,index) in itemsComp" :key="item.itemData.units">
             <div class="remove" @click="remove(item.itemData.id)">X</div>
             <div class="col-md-3">
                <div class="name" v-text="item.itemData.name"></div>
                </div>
                <div class="col-md-3">
                <div class="pic" :style="'background-image:url('+ item.itemData.pic +')'"></div>
                </div>
                <div class="col-md-3">
                <div class="amount">
                    <span class="pls-min" @click="plus(index, $event)">+</span>
                    <input type="text" max="9" v-model="item.units" @change="changeUnits(index, $event)" min="1">
                     <span class="pls-min" @click="minus(index, $event)">-</span>

                </div>
                </div>
                <div class="col-md-3">
                    
                <div class="ttprice">סה"כ: {{ format_number(item.totalPrice) }} ש"ח</div>
                </div>
                <div class="clr"></div>
         </div>

         <div class="total_price" v-if="getTotal > 0"> סה"כ בסל הקניות: <span >{{format_number(getTotal)}} </span>ש"ח</div>
     </div>
     
    </div>
</template>
<script>
export default {
    props: ["items"],
    data(){
        return {
            
        };
    },
    methods:{
         add(id){
            
            for (let i in this.items)
            {
                if (this.items[i].item_id == id)
                {
                    alert("מוצר זה קיים בסל הקניות");
                    return false;
                }
            }

            this.items.push({ item_id: id, units: 1 });
        },
        remove(id)
        {
            var index = false;
            for (let i in this.items)
            {
                if (this.items[i].item_id == id)
                {
                    index = i;
                    break;
                }
            }

            if (index !== false) this.items.splice(index, 1);

        },
        changeUnits(i, event)
        {
            this.items[i].units = event.target.value;
        },
          plus(i)
        {
             if (this.items[i].units < 10)
            {
            this.items[i].units = (this.items[i].units + 1);

            }
        },
          minus(i)
        {
            if (this.items[i].units > 1)
            {
            this.items[i].units = (this.items[i].units - 1);

            }
        }
      
       
    },
    computed:{
          getTotal(){
            var ret = 0;
            for(let i in this.itemsComp)
            {
                ret = ret + (this.itemsComp[i].units * this.itemsComp[i].itemData.price_discount);
            }

            return ret;
        },
        itemsComp: {
            // getter
            get: function () {
                var ret = [];
                for(let i in this.items)
                {
                    let itemData = this.$store.products[this.items[i].item_id];

                    ret.push({
                        itemData: itemData,
                        units: this.items[i].units,
                        totalPrice: this.items[i].units * itemData.price_discount
                    })
                }

                return ret;
                
            },
            // setter
            set: function (newValue) { alert(newValue);

            }



        }
    }
    
}
</script>
<style lang="scss" scoped>
.basket{
    .basket_title{color: red;}
    .clr{margin-bottom: 30px !important;}
    .pls-min{    height: 35px;display: inline-block;width: 35px;text-align: center;background-color: #fff;color: #000;line-height: 33px;margin: 0px;cursor: pointer;border: 1px solid;vertical-align: middle;}
    h4{font-size: 30px;margin: 20px auto;text-align: center;}
    .total_price{text-align: left;margin: 10px 0;}
    &{padding: 30px 0;}
    .items{
        width: 60%;margin:0 auto;min-height:100px;
        .item{padding: 15px 0;margin-bottom: 10px;  border-bottom: 2px solid #eee;position: relative;
        .remove{position: absolute;right: -50px;top: 50px;font-size: 20px;font-weight: bolder;cursor: pointer;}
        .pic{width: 50%; margin: 0 40px;height: 80px;background-size: contain;background-repeat: no-repeat;}
        input{    height: 35px;text-align: center;width: 35px;margin-top: 25px;color: #000;}
        }
        .ttprice{    margin-top: 30px;font-weight: 600;}
    }
    .total_price{font-weight: bolder;margin: 30px auto;}
}
</style>