<template>
  <div id="app" :class="'rtl'">
    
    <main id="page-wrap">

      <div id="page_content">
        
        <template v-if="loaded">
            <LoginPage v-if=" goto_login && !close_popup" />
            <Home @show_loader="loaded=false" @loaded="loaded=true" v-if="!goto_login" />
        </template>
      </div>

    </main>
    
    <div v-if="!loaded" id="app_loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>

  </div>
</template>

<script>

window.api_url = 'https://charbroil.bagelcms.com/api_vue/'; 

import LoginPage from '@/components/login.vue'

import Home from '@/views/Home.vue'

export default {
  name: 'app',
  components: { Home,LoginPage},
  data(){
    return {
      loaded: false,
      close_popup: false,
      goto_login: false,
      user_data: {},
    }
    
  },
  methods: {
  },
  mounted() {
    window.appVue = this;
    var self = this;
    // localStorage.clear('is_logged');
      // categories 
       if (localStorage.getItem('is_logged'))
        {          
         
          
      
      this.net_api({ action: 'general' , data:{id:localStorage.getItem('platform'), path:window.location.pathname }}, function(data){ 
              if(!data.data.user)  self.goto_login = true;
              else{
                self.goto_login = false;
              }
        
        self.$store.products = data.data.products;
        self.$store.platform = data.data.platform_data;
        self.$store.logo = data.data.logo;
        self.$store.takanon = data.data.takanon;

        self.loaded = true;

      } );
     
        }
           else{
              this.net_api({ action: 'general' , data:{}}, function(data){ 
                
        
        self.$store.products = data.data.products;
        self.$store.platform = data.data.platform_data;
        self.$store.logo = data.data.logo;
        self.$store.takanon = data.data.takanon;
        self.goto_login = true;
        self.loaded = true;

      } );
     
          
        }

    

  }
}
</script>