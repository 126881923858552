<template>
    <div>
        <div class="hero_pic">
            <div class="logo_grill"></div>
            <div class="text"><div class="grill"></div></div>
        </div>
       
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" >
 .hero_pic{height: 60vh;width: 100%;;position: relative;background-size: cover;background-image:url(../assets/fire.jpg); background-position: center center;text-align:center;
 .text{width: 100%;    height: 130px; max-width: 1110px; background-image: url(/img/text.c888c920.png); background-size: contain; background-repeat: no-repeat; margin: 0 auto; position: relative; background-position: right;}
 .logo_grill{height: 300px;width: 100%;background-image:url(../assets/logo_grill.png);background-size: contain;background-repeat: no-repeat;background-position: center;}
 .grill{position: absolute;height: 360px;width: 360px;top: 0px;left: 0;background-image:url(../assets/grill.png);background-size: cover;}
 }
 
</style>