<template>
  <div class="home">
    <Hero/>
    <Committee/>
    <div class="centered">
      <Prouduct v-for="prod in $store.products" :key="prod.id" :prod="prod" @addToBasket="addToBasket" />
          <div class="clr"></div>

      <Basket ref="basketObj" :items="items" />
      <PersonalInfo :items="items" />
      
    </div>
    <div class="footer">
      
        <h4 >לשאלות ובירורים צור איתנו קשר בטלפון <a href="tel:0733156660">073-3156660</a> או במייל grill@charbroil.co.il </h4>
      </div>
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import Committee from '../components/Committee.vue'
import Prouduct from '../components/Prouduct.vue'
import Basket from '../components/Basket.vue'
import PersonalInfo from '../components/PersonalInfo.vue'

export default {
  components:{
    Hero,Committee,Prouduct,Basket,PersonalInfo
  },
  data(){
    return {
      items:[],
     itemsComp: []
    }
    
  },
  methods:{
    addToBasket(id)
    {
      this.$refs.basketObj.add(id);
    },
    
        
  },
   computed:{
      
    }
  
}
</script>
<style lang="scss" scoped>

.home{    width: 100%;min-height: 100vh;padding-bottom: 300px;position: relative;}
.footer{position: absolute;bottom: 0;width: 100%;background-image: url(../assets/footer.jpg);height: 80px;text-align: center;color: #fff;line-height: 75px;font-size: 25px;}
.footer a{color: #fff !important;}
</style>
