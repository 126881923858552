import Vue from 'vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

import VueResource from 'vue-resource'
Vue.use(VueResource);
import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueNumericInput from 'vue-numeric-input';
 
Vue.use(VueNumericInput)

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//import 'vue-material/dist/theme/default-dark.css'
Vue.use(VueMaterial)

import './app.scss' 


Vue.mixin({
 
  data(){
    return {
    }
  },
  computed: {
  },
  methods: {
    format_number: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull: function (str)
    {
      return (str == '' || str == null || str == undefined);
    },
    pushIfNotExist(element, comp) {
      if (element.indexOf(comp) == -1) {
        element.push(comp);
      }
    },
    pushOrPull(element, comp) {
      var index = element.indexOf(comp);
      if (index == -1) {
        element.push(comp);
      }
      else
      {
        element.splice(index, 1);
      }
    },
    inArray(element, comp) {
      return (element.indexOf(comp) != -1);
    },

  net_api: function(settings, func, failFunc){
    
    // var is_test = false;


     var headers = {
       'Content-Type': 'application/json; charset=utf-8',
     };
    
     //if(!this.isNull(localStorage.getItem('user_token'))) 
     //{ 
       //headers.Authorization = localStorage.getItem('user_token');
       headers.Authorization = window.location.href;
     //}
   

    // if (!is_test)
    // {
       if (settings.method == 'get')
       {
         this.$http.get(window.api_url + settings.action, /* settings.data,*/ {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
            if (response.body == 'error')
            {
              localStorage.setItem('user_token', '');
              document.location.reload();
            }
             func(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
         });

       }
       else
       {
         this.$http.post(window.api_url + settings.action, settings.data, {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
          if (response.body == 'error')
          {
            localStorage.setItem('user_token', '');
            document.location.reload();
          }
             func(response.body);
             //console.log(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
             //console.error(response.body);
         });
     }
     

   },

    is_mobile: function(){ 

          return ( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      // || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      );
    },

   }
  })


new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
