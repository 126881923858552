<template>
    <div class="centered">
       <div class="logo" :style="'background-image:url('+$store.logo+')'"></div>
       <div class="committee_description" v-html="$store.platform.short_text">ש המון גרסאות זמינות לפסקאות של Lorem Ipsum. אבל רובם עברו שינויים בצורה זו או אחרת, על ידי השתלת הומור או מילים אקראיות שלא נראות אפילו מעט אמינות. אם אתה הולך להשתמש במקטעים של של Lorem Ipsum אתה צריך להיות בטוח שאין משהו מביך חבוי בתוך הטקסט. כל מחוללי הטקסט של Lorem Ipsum שנמצאים ברשת האינטרנט מכוונים לחזור על טקסטים מוגדרים מראש לפי הנדרש. וזה הופך אותנו למחוללי טקסט אמיתיים ראשונים באינטרנט.</div>
       <div class="col-md-6">
                 <div class="content" v-html="$store.platform.video_text" v-if="$store.platform.video_text">ש המון גרסאות זמינות לפסקאות של Lorem Ipsum. אבל רובם עברו שינויים בצורה זו או אחרת, על ידי השתלת הומור או מילים אקראיות שלא נראות אפילו מעט אמינות. אם אתה הולך להשתמש במקטעים של של Lorem Ipsum אתה צריך להיות בטוח שאין משהו מביך חבוי בתוך הטקסט. כל מחוללי הטקסט של Lorem Ipsum שנמצאים ברשת האינטרנט מכוונים לחזור על טקסטים מוגדרים מראש לפי הנדרש. וזה הופך אותנו למחוללי טקסט אמיתיים ראשונים באינטרנט.</div>
       </div>
       <div class="col-md-6">
           <div class="youtube" v-if="$store.platform.video">
           <iframe id="ytplayer" type="text/html" width="100%" height="100%"
            :src="'https://www.youtube.com/embed/'+ $store.platform.video+'?autoplay=1&origin=http://example.com'"
            frameborder="0"></iframe>
            
       </div>
       </div>
       <div class="clr"></div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" >
iframe{height: 350px !important}

.youtube{height: 500px;}
 .logo{display: block;height: 130px;width: 300px;margin: 50px auto;background-size: contain;background-position: center;background-repeat: no-repeat;}
 .committee_description{width: 70%;margin: 0 auto;text-align: center;line-height: 29px;margin-bottom: 50px;}
 .content{width: 90%;line-height: 27px;margin-bottom: 40px;}
 .infra_red{font-size: 40px;font-weight: 400;color: red;margin-bottom: 20px;line-height: 32px;}
 .clr{margin-bottom: 100px;}
</style>