<template>
    <div>
     <h3 v-if="!go_to_payment">פרטים אישיים:</h3>
     <div class="form" ref="basket"> 
        <form v-on:submit.prevent="save();scrollToBottom()" method="post" v-if="!go_to_payment">
         <div class="name col-md-3">
         <md-field>
            <label>שם פרטי</label>
            <md-input required v-model="info.first_name"></md-input>
        </md-field>
        </div>
        <div class="last_name col-md-3">
         <md-field>
            <label>שם משפחה</label>
            <md-input required v-model="info.last_name"></md-input>
        </md-field>
        </div>
        <div class="email col-md-3">
         <md-field>
            <label>אימייל</label>
            <md-input required v-model="info.email" type="email"></md-input>
        </md-field>
        </div>
        <div class="phone col-md-3">
         <md-field>
            <label>טלפון</label>
            <md-input required v-model="info.phone" type="phone" name="phone"></md-input>
        </md-field>
        </div>
        <div class="street col-md-4">
         <md-field>
            <label>רחוב</label>
            <md-input required v-model="info.street"></md-input>
        </md-field>
        </div>
        <div class="apartment col-md-2">
         <md-field>
            <label>מס דירה</label>
            <md-input required v-model="info.apartment"></md-input>
        </md-field>
        </div>
        <div class="city col-md-3">
        <md-field>
            <label>עיר </label>
            <md-input required v-model="info.city"></md-input>
        </md-field>
        <!-- <md-field>
            <label>עיר</label>
            <md-input required v-model="info.city"></md-input>
        </md-field>-->
        </div>
        <div class="notes col-md-3">
         <md-field>
            <label>הערות</label>
            <md-input  v-model="info.notes"></md-input>
        </md-field>
        </div>
        <div class="clr"></div>
        <div class="input_text">
         <input type="checkbox" name="a" required> 
            <p>* אני מאשר שקראתי ואני מסכימ/ה <a @click="showDialogg = true">לפרטי התקנון של האתר</a></p>
               </div>
             <div class="input_text">
                <input type="checkbox" name="b" required>      
            <p> * אני מבין שהמשלוח הוא לקומת קרקע ללא מדרגות </p>
                 </div>
             <div class="input_text">
                <input type="checkbox" name="c" v-model="info.club">
                 <p> אני מעוניין להצטרף למודעון הלקוחות של CharBroil ולקבל מסוכות נחלים בע"מ הצעות לבצעים, הנחות ותכנים שיווקים ופרסומיים באמצעות דוא"ל/מסרונים </p>
             </div>
             
               <input type="submit" value="המשך" class="sub_btn" >
               </form>
     </div>
       <md-dialog :md-active.sync="showDialogg" >
      <md-dialog-title>תקנון האתר</md-dialog-title>
<div class="close_pop"  @click="showDialogg = false">X</div>

      <md-tabs md-dynamic-height>
        <md-tab md-label="תקנון">
        
<div class="takanon" v-html="$store.takanon"></div>
        </md-tab>
      </md-tabs>

    </md-dialog>
    <div v-if="go_to_payment" class="iframe" id="iframe">
       <iframe  :src="payment_link" :key="payment_link"  frameborder="0" ></iframe>
           </div>
    </div>
</template>
<script>

export default {
    props:["items" , "platform_id"],
    data(){
        return{
            payment_link: '',
            go_to_payment: false,
         showDialogg: false,
         cities:[],
          info: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          street: '',
          apartment: '',
          city: '',
          zip: '',
          notes: '',
          club: '',
          platform_id:this.$store.platform.id
      }

        }
    },
    mounted(){
        var self = this;
            this.net_api({ action: 'cities', data:{} ,}, function(data){ 
                  self.cities = data.data
            });
    },
   
    methods: {
        
        payment(){
              this.net_api({ action: 'payments/vach', data:{id:20} ,}, function(){ 
                  
            });
        },
          scrollToBottom() {
   window.scroll({
  top: 3500, 
  left: 0, 
  behavior: 'smooth' 
});
  },
    save(){
       
    if(isNaN(this.info.last_name) && isNaN(this.info.first_name) && !isNaN(this.info.phone) && this.info.phone.length > 8)
    {
    var self = this;
    this.net_api({ action: 'order', data: { info: this.info, basket: this.items } } , function(data){ 
        self.payment = data.data
        
        self.payment_link = 'https://charbroil.bagelcms.com/payments/do_redirect/' + data.data;
        self.go_to_payment = true;
       
    }  );
    }
     else if(!isNaN(this.info.last_name)){
        alert("שם משפחה לא תקין");
    }
     else if(!isNaN(this.info.first_name)){
        alert("שם  לא תקין");
    }
    else if(this.info.phone.length < 8){
        alert("מספר פלאפון קצר מדי");
    }
    else if(isNaN(this.info.phone)){
        alert("מספר פלאפון  לא תקין");
    }
    else{
    this.net_api({ action: 'order', data: { info: this.info, basket: this.items } } , function(data){ 
        self.payment = data.data
        
        self.payment_link = 'https://charbroil.bagelcms.com/payments/do_redirect/' + data.data;
        self.go_to_payment = true;
       
    }  );
    }
    
    
 
    },
 
   
    
    
    
  }

}
</script>
<style lang="scss">
.iframe iframe{height: 800px  !important;display: block;width: 50%;margin: 0 auto;}
.form{position: relative;}
.takanon{height: 300px; overflow: auto;padding: 0px 30px 20px 0;}
.sub_btn{margin: 20px auto;width: 150px;font-size: 25px;background: #010c46;color: #fff;border-radius: 8px;border: none;padding: 5px;position: absolute;bottom: -100px;left: 0;right:  0;cursor: pointer;}
.input_text p{display: inline-block;font-size: 20px;padding-right: 5px;margin-bottom: 10px;}
.md-field label{left: auto;right: 20px;color: #000  !important;font-weight: 600 !important;}
.md-field input{border: 1px solid #ccc !important ;height: 45px  !important;padding-right: 20px !important;}
.md-field.md-theme-default:after{display: none;}
</style> 